/* Reduce font size and adjust padding */
.table th, .table td {
  font-size: 14px; /* Reduce font size */ /* Reduce padding inside table cells */
}

.table th {
  font-weight: bold;
}

.table-responsive {
  font-size: 12px; /* Reduce font size for the table */
}

.document-section h2 {
  font-size: 18px; /* Smaller title font size */
}

.document-section .pagination button {
  font-size: 12px; /* Smaller pagination buttons */
}

.document-section .form-control {
  font-size: 12px; /* Smaller search input font size */
}

.document-section .alert {
  font-size: 12px; /* Smaller success message font size */
}
