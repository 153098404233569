@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?orkqwr');
  src: url('fonts/icomoon.eot?orkqwr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?orkqwr') format('truetype'),
    url('fonts/icomoon.woff?orkqwr') format('woff'),
    url('fonts/icomoon.svg?orkqwr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





.icon-airplane:before {
  content: "\e900";
}

.icon-angle-left:before {
  content: "\e901";
}

.icon-angle-right:before {
  content: "\e902";
}

.icon-arrow-down-left:before {
  content: "\e903";
}

.icon-arrow-down-right:before {
  content: "\e904";
}

.icon-arrow-left:before {
  content: "\e905";
}

.icon-arrow-right:before {
  content: "\e906";
}

.icon-calender:before {
  content: "\e907";
}

.icon-call:before {
  content: "\e908";
}

.icon-chat-1:before {
  content: "\e909";
}

.icon-clock:before {
  content: "\e90a";
}

.icon-comments:before {
  content: "\e90b";
}

.icon-comments-2:before {
  content: "\e90c";
}

.icon-container:before {
  content: "\e90d";
}

.icon-conveyor-1:before {
  content: "\e90e";
}

.icon-crane:before {
  content: "\e90f";
}

.icon-customer-service:before {
  content: "\e910";
}

.icon-dabble-arrow:before {
  content: "\e911";
}

.icon-down-arrow:before {
  content: "\e912";
}

.icon-envolop:before {
  content: "\e913";
}

.icon-facebook-f:before {
  content: "\e914";
}

.icon-fragile:before {
  content: "\e915";
}

.icon-instagram:before {
  content: "\e916";
}

.icon-ionic-ios-people:before {
  content: "\e917";
}

.icon-lift-truck:before {
  content: "\e918";
}

.icon-linkedin-in:before {
  content: "\e919";
}

.icon-location:before {
  content: "\e91a";
}

.icon-menu:before {
  content: "\e91b";
}

.icon-minus:before {
  content: "\e91c";
}

.icon-paper-plane:before {
  content: "\e91d";
}

.icon-phone:before {
  content: "\e91e";
}

.icon-play:before {
  content: "\e91f";
}

.icon-plus:before {
  content: "\e920";
}

.icon-postbox:before {
  content: "\e921";
}

.icon-quote:before {
  content: "\e922";
}

.icon-schedule:before {
  content: "\e923";
}

.icon-search:before {
  content: "\e924";
}

.icon-shipping:before {
  content: "\e925";
}

.icon-shopping-cart:before {
  content: "\e926";
}

.icon-star:before {
  content: "\e927";
}

.icon-user:before {
  content: "\e928";
}

.icon-user-2:before {
  content: "\e929";
}

.icon-Vector:before {
  content: "\e92a";
}